import { createRouter, createWebHistory } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import { routes, handleHotUpdate } from 'vue-router/auto-routes'
import qs from 'qs';
const alwaysArrayKeys = ['locationIDs', 'groupIDs', 'modeNames', 'itemNames', 'tenderTypes', 'workstations', 'departments', 'discounts'];

const parseQuery = (query) => {
    const parsed = qs.parse(query);

    // Forcer les variables spécifiques à être toujours des tableaux
    alwaysArrayKeys.forEach((key) => {
        if (parsed[key] && !Array.isArray(parsed[key])) {
            parsed[key] = [parsed[key]];
        }
    });

    return parsed;
};

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: setupLayouts(routes),
    parseQuery
})

const guard = async (to) => {
    const publicPages = ['/login', '/forgotpassword', '/contact', '/reset-password']
    const authRequired = !publicPages.includes(to.path)
    const auth = useAuthStore()

    if (to.path === '/') {
        return '/login'
    }

    if (!authRequired && auth.user?.id) {

        return auth.returnUrl || '/app/dashboard'
    }

    if (authRequired && !auth.user?.id) {
        auth.returnUrl = to.fullPath

        return '/login'
    }
}

router.beforeEach(guard)

if (import.meta.hot) {
    handleHotUpdate(router)
}

export default router

export const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/public/index.vue'),
    /* no children */
    meta: {
      "layout": false
    }
  },
  {
    path: '/:all(.*)',
    name: 'all',
    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/public/[...all].vue'),
    /* no children */
    meta: {
      "layout": 404
    }
  },
  {
    path: '/app',
    /* internal name: 'app' */
    /* no component */
    children: [
      {
        path: 'analytics',
        name: 'app-analytics',
        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/analytics.vue'),
        /* no children */
        meta: {
          "layout": "home"
        }
      },
      {
        path: 'dashboard',
        name: 'app-dashboard',
        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/dashboard.vue'),
        /* no children */
        meta: {
          "width": "full",
          "layout": "home-no-scroll",
          "showExport": false,
          "showGroups": true,
          "showLocations": true,
          "showCurrency": true,
          "showDate": true,
          "showSubmit": true,
          "showPresets": true,
          "disableMultiselect": false,
          "pageHeaderMenuTitle": "Dashboard options",
          "pageHeaderMenuDescription": "Choose the settings and customize your dashboard."
        }
      },
      {
        path: 'profile-settings',
        name: 'app-profile-settings-root',
        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/profile-settings.vue'),
        children: [
          {
            path: '',
            name: 'app-profile-settings',
            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/profile-settings/index.vue'),
            /* no children */
            meta: {
              "width": "small",
              "disableHeading": true,
              "layout": false
            }
          },
          {
            path: 'behavior',
            name: 'app-profile-settings-behavior',
            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/profile-settings/behavior.vue'),
            /* no children */
            meta: {
              "layout": "home-scroll",
              "width": "small",
              "disableHeading": true
            }
          }
        ],
        meta: {
          "layout": "user-profile-layout",
          "showToolbar": false
        }
      },
      {
        path: 'reports',
        /* internal name: 'app-reports-root' */
        /* no component */
        children: [
          {
            path: '',
            name: 'app-reports',
            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/index.vue'),
            /* no children */
            meta: {
              "layout": "home",
              "showToolbar": false
            }
          },
          {
            path: 'check',
            /* internal name: 'app-reports-check-root' */
            /* no component */
            children: [
              {
                path: '',
                name: 'app-reports-check',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/check/index.vue'),
                /* no children */
                meta: {
                  "layout": "home",
                  "showToolbar": false
                }
              },
              {
                path: 'search',
                /* internal name: 'app-reports-check-search-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-reports-check-search',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/check/search/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "hideGlobalErrorDialog": true,
                      "width": "medium",
                      "paginator": true,
                      "paginationLabel": "checks",
                      "showExport": true,
                      "showGroups": true,
                      "showLocations": true,
                      "showCurrency": true,
                      "showDateRange": false,
                      "showSubmit": false,
                      "disableMultiselect": false,
                      "disablePdf": false,
                      "disableExcel": true,
                      "showToolbar": true,
                      "showSearch": true,
                      "showPresets": true,
                      "pageHeaderMenuTitle": "Actions",
                      "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                      "doNotHandleSelectionChange": true
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              },
              {
                path: 'summary',
                /* internal name: 'app-reports-check-summary-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-reports-check-summary',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/check/summary/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "checks",
                      "showExport": true,
                      "showGroups": true,
                      "showLocations": true,
                      "showCurrency": true,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": false,
                      "disablePdf": true,
                      "showPresets": true,
                      "disableExcel": false,
                      "showSelected": false,
                      "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                    }
                  },
                  {
                    path: ':id',
                    /* internal name: 'app-reports-check-summary-id-root' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'app-reports-check-summary-id',
                        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/check/summary/[id]/index.vue'),
                        /* no children */
                        meta: {
                          "layout": "home-no-scroll",
                          "width": "small",
                          "disableHeading": true,
                          "disableFooter": true,
                          "showExport": true,
                          "showPresets": true
                        }
                      }
                    ],
                    meta: {
                      "layout": false
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              }
            ],
            meta: {
              "layout": false
            }
          },
          {
            path: 'employees',
            /* internal name: 'app-reports-employees-root' */
            /* no component */
            children: [
              {
                path: '',
                name: 'app-reports-employees',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/employees/index.vue'),
                /* no children */
                meta: {
                  "layout": "home",
                  "showToolbar": false
                }
              },
              {
                path: 'manageractivity',
                /* internal name: 'app-reports-employees-manageractivity-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-reports-employees-manageractivity',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/employees/manageractivity/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "invoices",
                      "showExport": true,
                      "showGroups": false,
                      "showLocations": true,
                      "showRevenueCenter": false,
                      "showConsolidated": false,
                      "showCurrency": true,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": true,
                      "disablePdf": true,
                      "showPresets": false,
                      "disableExcel": false,
                      "showSelected": false
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              },
              {
                path: 'waiterperformance',
                /* internal name: 'app-reports-employees-waiterperformance' */
                /* no component */
                children: [
                  {
                    path: 'details',
                    name: 'app-reports-employees-waiterperformance-details',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/employees/waiterperformance/details.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "employees",
                      "showExport": true,
                      "showGroups": false,
                      "showLocations": true,
                      "showRevenueCenter": false,
                      "showConsolidated": false,
                      "showCurrency": true,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": true,
                      "disablePdf": false,
                      "disableExcel": true,
                      "showSelected": false,
                      "showPresets": false,
                      "generateTo": "app-reports-employees-waiterperformance-details"
                    }
                  },
                  {
                    path: 'summary',
                    name: 'app-reports-employees-waiterperformance-summary',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/employees/waiterperformance/summary.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "employees",
                      "showExport": true,
                      "showGroups": false,
                      "showLocations": true,
                      "showRevenueCenter": false,
                      "showConsolidated": false,
                      "showCurrency": true,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": true,
                      "disablePdf": true,
                      "disableExcel": false,
                      "showSelected": false,
                      "showPresets": false,
                      "disableExport": false,
                      "generateTo": "app-reports-employees-waiterperformance-summary"
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              }
            ],
            meta: {
              "layout": false
            }
          },
          {
            path: 'financial',
            /* internal name: 'app-reports-financial-root' */
            /* no component */
            children: [
              {
                path: '',
                name: 'app-reports-financial',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/financial/index.vue'),
                /* no children */
                meta: {
                  "layout": "home",
                  "showToolbar": false
                }
              },
              {
                path: 'audit',
                /* internal name: 'app-reports-financial-audit-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-reports-financial-audit',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/financial/audit/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "locations",
                      "showExport": true,
                      "showGroups": true,
                      "showLocations": true,
                      "showConsolidated": true,
                      "showRevenueCenter": true,
                      "showCurrency": true,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": false,
                      "disablePdf": true,
                      "showPresets": true,
                      "disableExcel": false,
                      "showSelected": false,
                      "generateSingleLocationTo": "app-reports-financial-audit-id",
                      "generateSingleLocationRcTo": "app-reports-financial-audit-id",
                      "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                    }
                  },
                  {
                    path: ':id',
                    /* internal name: 'app-reports-financial-audit-id-root' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'app-reports-financial-audit-id',
                        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/financial/audit/[id]/index.vue'),
                        /* no children */
                        meta: {
                          "layout": "pagination",
                          "width": "full",
                          "paginator": true,
                          "paginationLabel": "locations",
                          "showExport": true,
                          "showGroups": true,
                          "showLocations": true,
                          "showConsolidated": true,
                          "showRevenueCenter": true,
                          "showCurrency": true,
                          "showDateRange": true,
                          "showSubmit": true,
                          "disableMultiselect": false,
                          "generateTo": "app-reports-financial-audit",
                          "disablePdf": true,
                          "disableExcel": false,
                          "showSelected": false,
                          "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                        }
                      },
                      {
                        path: 'revenuecenter',
                        /* internal name: 'app-reports-financial-audit-id-revenuecenter' */
                        /* no component */
                        children: [
                          {
                            path: ':remoteId',
                            name: 'app-reports-financial-audit-id-revenuecenter-remoteid',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/financial/audit/[id]/revenuecenter/[remoteId].vue'),
                            /* no children */
                            meta: {
                              "layout": "pagination",
                              "width": "full",
                              "paginator": true,
                              "paginationLabel": "locations",
                              "showExport": true,
                              "showGroups": true,
                              "showLocations": true,
                              "showConsolidated": true,
                              "showRevenueCenter": true,
                              "showCurrency": true,
                              "showDateRange": true,
                              "showSubmit": true,
                              "disableMultiselect": false,
                              "generateTo": "app-reports-financial-audit",
                              "disablePdf": true,
                              "disableExcel": false,
                              "showSelected": false,
                              "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                            }
                          }
                        ],
                        meta: {
                          "layout": false
                        }
                      }
                    ],
                    meta: {
                      "layout": false
                    }
                  },
                  {
                    path: 'revenuecenters',
                    /* internal name: 'app-reports-financial-audit-revenuecenters' */
                    /* no component */
                    children: [
                      {
                        path: ':remoteId',
                        name: 'app-reports-financial-audit-revenuecenters-remoteid',
                        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/financial/audit/revenuecenters/[remoteId].vue'),
                        /* no children */
                        meta: {
                          "layout": "pagination",
                          "width": "full",
                          "paginator": true,
                          "paginationLabel": "dates",
                          "showExport": true,
                          "showGroups": true,
                          "showLocations": true,
                          "showConsolidated": true,
                          "showRevenueCenter": true,
                          "showCurrency": true,
                          "showDateRange": true,
                          "showSubmit": true,
                          "disableMultiselect": false,
                          "generateTo": "app-reports-financial-audit",
                          "disablePdf": true,
                          "disableExcel": false,
                          "showSelected": false,
                          "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                        }
                      }
                    ],
                    meta: {
                      "layout": false
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              },
              {
                path: 'categorysales',
                /* internal name: 'app-reports-financial-categorysales-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-reports-financial-categorysales',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/financial/categorysales/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "locations",
                      "showExport": true,
                      "showGroups": true,
                      "showLocations": true,
                      "showConsolidated": true,
                      "showRevenueCenter": true,
                      "showCurrency": true,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": false,
                      "disablePdf": true,
                      "showPresets": true,
                      "disableExcel": false,
                      "showSelected": false,
                      "generateSingleLocationTo": "app-reports-financial-categorysales-id",
                      "generateSingleLocationRcTo": "app-reports-financial-categorysales-id",
                      "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                    }
                  },
                  {
                    path: ':id',
                    /* internal name: 'app-reports-financial-categorysales-id-root' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'app-reports-financial-categorysales-id',
                        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/financial/categorysales/[id]/index.vue'),
                        /* no children */
                        meta: {
                          "layout": "pagination",
                          "width": "full",
                          "paginator": true,
                          "showExport": true,
                          "showGroups": true,
                          "showLocations": true,
                          "showConsolidated": true,
                          "showRevenueCenter": true,
                          "showCurrency": true,
                          "showDateRange": true,
                          "showSubmit": true,
                          "disableMultiselect": false,
                          "disablePdf": true,
                          "showPresets": true,
                          "disableExcel": false,
                          "showSelected": false,
                          "pageHeaderMenuTitle": "Actions",
                          "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                          "generateTo": "app-reports-financial-categorysales",
                          "paginationLabel": "dates",
                          "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                        }
                      },
                      {
                        path: ':date',
                        /* internal name: 'app-reports-financial-categorysales-id-date-root' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: 'app-reports-financial-categorysales-id-date',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/financial/categorysales/[id]/[date]/index.vue'),
                            /* no children */
                            meta: {
                              "layout": "pagination",
                              "width": "full",
                              "paginator": true,
                              "showExport": true,
                              "showGroups": true,
                              "showLocations": true,
                              "showConsolidated": true,
                              "showRevenueCenter": true,
                              "showCurrency": true,
                              "showDateRange": true,
                              "showSubmit": true,
                              "disableMultiselect": false,
                              "disablePdf": true,
                              "showPresets": true,
                              "disableExcel": false,
                              "showSelected": false,
                              "pageHeaderMenuTitle": "Actions",
                              "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                              "generateTo": "app-reports-financial-categorysales",
                              "paginationLabel": "dates",
                              "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                            }
                          },
                          {
                            path: ':info',
                            name: 'app-reports-financial-categorysales-id-date-info',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/financial/categorysales/[id]/[date]/[info].vue'),
                            /* no children */
                            meta: {
                              "layout": "pagination",
                              "width": "full",
                              "paginator": true,
                              "showExport": true,
                              "showGroups": true,
                              "showLocations": true,
                              "showConsolidated": true,
                              "showRevenueCenter": true,
                              "showCurrency": true,
                              "showDateRange": true,
                              "showSubmit": true,
                              "disableMultiselect": false,
                              "disablePdf": true,
                              "showPresets": true,
                              "disableExcel": false,
                              "showSelected": false,
                              "pageHeaderMenuTitle": "Actions",
                              "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                              "generateTo": "app-reports-financial-categorysales",
                              "paginationLabel": "categories",
                              "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                            }
                          }
                        ],
                        meta: {
                          "layout": false
                        }
                      }
                    ],
                    meta: {
                      "layout": false
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              },
              {
                path: 'consolidated',
                /* internal name: 'app-reports-financial-consolidated-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-reports-financial-consolidated',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/financial/consolidated/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "disableExport": false,
                      "paginationLabel": "locations",
                      "pageHeaderMenuTitle": "Actions",
                      "labelExport": "Export report",
                      "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                      "showExport": true,
                      "showPresets": true,
                      "showGroups": true,
                      "showLocations": true,
                      "showConsolidated": true,
                      "showRevenueCenter": false,
                      "showCurrency": true,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": false,
                      "disablePdf": false,
                      "disableExcel": true,
                      "showSelected": false
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              },
              {
                path: 'summary',
                name: 'app-reports-financial-summary',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/financial/summary.vue'),
                /* no children */
                meta: {
                  "width": "full",
                  "layout": "home-no-scroll",
                  "showExport": false,
                  "showGroups": true,
                  "showLocations": true,
                  "showCurrency": true,
                  "showDate": false,
                  "showDateRange": true,
                  "showSubmit": true,
                  "showPresets": true,
                  "disableMultiselect": false,
                  "pageHeaderMenuTitle": "Dashboard options",
                  "pageHeaderMenuDescription": "Choose the settings and customize your dashboard."
                }
              }
            ],
            meta: {
              "layout": false
            }
          },
          {
            path: 'transactions',
            /* internal name: 'app-reports-transactions-root' */
            /* no component */
            children: [
              {
                path: '',
                name: 'app-reports-transactions',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/index.vue'),
                /* no children */
                meta: {
                  "layout": "home",
                  "showToolbar": false
                }
              },
              {
                path: 'discount',
                /* internal name: 'app-reports-transactions-discount-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-reports-transactions-discount',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/discount/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "locations",
                      "showExport": true,
                      "showGroups": true,
                      "showLocations": true,
                      "showConsolidated": true,
                      "showRevenueCenter": true,
                      "showCurrency": true,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": false,
                      "disablePdf": true,
                      "showPresets": true,
                      "disableExcel": false,
                      "showSelected": false,
                      "generateSingleLocationTo": "app-reports-transactions-discount-id",
                      "generateSingleLocationRcTo": "app-reports-transactions-discount-id-revenuecenter",
                      "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                    }
                  },
                  {
                    path: ':id',
                    /* internal name: 'app-reports-transactions-discount-id-root' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'app-reports-transactions-discount-id',
                        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/discount/[id]/index.vue'),
                        /* no children */
                        meta: {
                          "layout": "pagination",
                          "width": "full",
                          "paginator": true,
                          "showExport": true,
                          "showGroups": true,
                          "showLocations": true,
                          "showConsolidated": true,
                          "showRevenueCenter": true,
                          "showCurrency": true,
                          "showDateRange": true,
                          "showSubmit": true,
                          "disableMultiselect": false,
                          "disablePdf": true,
                          "showPresets": true,
                          "disableExcel": false,
                          "showSelected": false,
                          "generateTo": "app-reports-transactions-discount",
                          "paginationLabel": "dates",
                          "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                        }
                      },
                      {
                        path: ':date',
                        /* internal name: 'app-reports-transactions-discount-id-date-root' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: 'app-reports-transactions-discount-id-date',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/discount/[id]/[date]/index.vue'),
                            /* no children */
                            meta: {
                              "layout": "pagination",
                              "width": "full",
                              "paginator": true,
                              "paginationLabel": "employees",
                              "showExport": true,
                              "showGroups": true,
                              "showLocations": true,
                              "showConsolidated": true,
                              "showRevenueCenter": true,
                              "showCurrency": true,
                              "showDateRange": true,
                              "showSubmit": true,
                              "disableMultiselect": false,
                              "disablePdf": true,
                              "showPresets": true,
                              "disableExcel": false,
                              "showSelected": false,
                              "generateTo": "app-reports-transactions-discount",
                              "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                            }
                          }
                        ],
                        meta: {
                          "layout": false
                        }
                      },
                      {
                        path: 'revenuecenter',
                        /* internal name: 'app-reports-transactions-discount-id-revenuecenter-root' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: 'app-reports-transactions-discount-id-revenuecenter',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/discount/[id]/revenuecenter/index.vue'),
                            /* no children */
                            meta: {
                              "layout": "pagination",
                              "width": "full",
                              "paginator": true,
                              "showExport": true,
                              "showGroups": true,
                              "showLocations": true,
                              "showConsolidated": true,
                              "showRevenueCenter": true,
                              "showCurrency": true,
                              "showDateRange": true,
                              "showSubmit": true,
                              "disableMultiselect": false,
                              "disablePdf": true,
                              "showPresets": true,
                              "disableExcel": false,
                              "showSelected": false,
                              "generateTo": "app-reports-transactions-discount",
                              "paginationLabel": "revenue centers",
                              "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                            }
                          },
                          {
                            path: ':remoteId',
                            /* internal name: 'app-reports-transactions-discount-id-revenuecenter-remoteid-root' */
                            /* no component */
                            children: [
                              {
                                path: '',
                                name: 'app-reports-transactions-discount-id-revenuecenter-remoteid',
                                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/discount/[id]/revenuecenter/[remoteId]/index.vue'),
                                /* no children */
                                meta: {
                                  "layout": "pagination",
                                  "width": "full",
                                  "paginator": true,
                                  "showExport": true,
                                  "showGroups": true,
                                  "showLocations": true,
                                  "showConsolidated": true,
                                  "showRevenueCenter": true,
                                  "showCurrency": true,
                                  "showDateRange": true,
                                  "showSubmit": true,
                                  "disableMultiselect": false,
                                  "disablePdf": true,
                                  "disableExcel": false,
                                  "showPresets": true,
                                  "showSelected": false,
                                  "generateTo": "app-reports-transactions-discount",
                                  "paginationLabel": "revenue centers",
                                  "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                                }
                              },
                              {
                                path: ':date',
                                name: 'app-reports-transactions-discount-id-revenuecenter-remoteid-date',
                                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/discount/[id]/revenuecenter/[remoteId]/[date].vue'),
                                /* no children */
                                meta: {
                                  "layout": "pagination",
                                  "width": "full",
                                  "paginator": true,
                                  "showExport": true,
                                  "showGroups": true,
                                  "showLocations": true,
                                  "showConsolidated": true,
                                  "showRevenueCenter": true,
                                  "showCurrency": true,
                                  "showDateRange": true,
                                  "showSubmit": true,
                                  "disableMultiselect": false,
                                  "disablePdf": true,
                                  "showPresets": true,
                                  "disableExcel": false,
                                  "showSelected": false,
                                  "generateTo": "app-reports-transactions-discount",
                                  "paginationLabel": "discounts",
                                  "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                                }
                              }
                            ],
                            meta: {
                              "layout": false
                            }
                          }
                        ],
                        meta: {
                          "layout": false
                        }
                      }
                    ],
                    meta: {
                      "layout": false
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              },
              {
                path: 'employeesales',
                /* internal name: 'app-reports-transactions-employeesales-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-reports-transactions-employeesales',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/employeesales/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "employees",
                      "showExport": true,
                      "showGroups": false,
                      "showLocations": true,
                      "showRevenueCenter": true,
                      "showCurrency": false,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": true,
                      "disablePdf": true,
                      "showPresets": false,
                      "disableExcel": false,
                      "generateTo": "app-reports-transactions-employeesales",
                      "showSelected": false,
                      "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                    }
                  },
                  {
                    path: 'chart',
                    name: 'app-reports-transactions-employeesales-chart',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/employeesales/chart.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "employees",
                      "showExport": true,
                      "showGroups": false,
                      "showLocations": true,
                      "showRevenueCenter": true,
                      "showCurrency": false,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": true,
                      "disablePdf": true,
                      "showPresets": false,
                      "disableExcel": false,
                      "showSelected": false,
                      "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              },
              {
                path: 'giftcard',
                /* internal name: 'app-reports-transactions-giftcard-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-reports-transactions-giftcard',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/giftcard/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "locations",
                      "showExport": true,
                      "showGroups": true,
                      "showLocations": true,
                      "showRevenueCenter": true,
                      "showConsolidated": false,
                      "showCurrency": true,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": false,
                      "disablePdf": true,
                      "showPresets": true,
                      "disableExcel": false,
                      "showSelected": false,
                      "generateSingleLocationTo": "app-reports-transactions-giftcard",
                      "generateSingleLocationRcTo": "app-reports-transactions-giftcard",
                      "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              },
              {
                path: 'itemssalesbycategory',
                /* internal name: 'app-reports-transactions-itemssalesbycategory-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-reports-transactions-itemssalesbycategory',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/itemssalesbycategory/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "locations",
                      "showExport": true,
                      "showGroups": true,
                      "showLocations": true,
                      "showConsolidated": true,
                      "showRevenueCenter": true,
                      "showCurrency": true,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": false,
                      "disablePdf": true,
                      "showPresets": true,
                      "disableExcel": false,
                      "showSelected": false,
                      "enableConsolidatedSingleLocation": true,
                      "generateSingleLocationTo": "app-reports-transactions-itemssalesbycategory-id",
                      "generateSingleLocationRcTo": "app-reports-transactions-itemssalesbycategory-id",
                      "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                    }
                  },
                  {
                    path: ':id',
                    /* internal name: 'app-reports-transactions-itemssalesbycategory-id-root' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'app-reports-transactions-itemssalesbycategory-id',
                        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/itemssalesbycategory/[id]/index.vue'),
                        /* no children */
                        meta: {
                          "layout": "pagination",
                          "width": "full",
                          "paginator": true,
                          "showExport": true,
                          "showGroups": true,
                          "showLocations": true,
                          "showConsolidated": true,
                          "showRevenueCenter": true,
                          "showCurrency": true,
                          "showDateRange": true,
                          "showSubmit": true,
                          "disableMultiselect": false,
                          "disablePdf": true,
                          "disableExcel": false,
                          "showPresets": true,
                          "showSelected": false,
                          "pageHeaderMenuTitle": "Actions",
                          "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                          "generateTo": "app-reports-transactions-itemssalesbycategory",
                          "paginationLabel": "dates",
                          "enableConsolidatedSingleLocation": true,
                          "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                        }
                      },
                      {
                        path: ':date',
                        /* internal name: 'app-reports-transactions-itemssalesbycategory-id-date-root' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: 'app-reports-transactions-itemssalesbycategory-id-date',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/itemssalesbycategory/[id]/[date]/index.vue'),
                            /* no children */
                            meta: {
                              "layout": "pagination",
                              "width": "full",
                              "paginator": true,
                              "showExport": true,
                              "showGroups": true,
                              "showLocations": true,
                              "showConsolidated": true,
                              "showRevenueCenter": true,
                              "showCurrency": true,
                              "showDateRange": true,
                              "showSubmit": true,
                              "disableMultiselect": false,
                              "disablePdf": true,
                              "showPresets": true,
                              "disableExcel": false,
                              "showSelected": false,
                              "pageHeaderMenuTitle": "Actions",
                              "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                              "generateTo": "app-reports-transactions-itemssalesbycategory",
                              "paginationLabel": "categories",
                              "enableConsolidatedSingleLocation": true,
                              "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                            }
                          },
                          {
                            path: ':category',
                            /* internal name: 'app-reports-transactions-itemssalesbycategory-id-date-category-root' */
                            /* no component */
                            children: [
                              {
                                path: '',
                                name: 'app-reports-transactions-itemssalesbycategory-id-date-category',
                                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/itemssalesbycategory/[id]/[date]/[category]/index.vue'),
                                /* no children */
                                meta: {
                                  "layout": "pagination",
                                  "width": "full",
                                  "paginator": true,
                                  "showExport": true,
                                  "showGroups": true,
                                  "showLocations": true,
                                  "showConsolidated": true,
                                  "showRevenueCenter": true,
                                  "showCurrency": true,
                                  "showDateRange": true,
                                  "showSubmit": true,
                                  "disableMultiselect": false,
                                  "disablePdf": true,
                                  "disableExcel": false,
                                  "showPresets": true,
                                  "showSelected": false,
                                  "pageHeaderMenuTitle": "Actions",
                                  "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                                  "generateTo": "app-reports-transactions-itemssalesbycategory",
                                  "paginationLabel": "categories",
                                  "enableConsolidatedSingleLocation": true,
                                  "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                                }
                              }
                            ],
                            meta: {
                              "layout": false
                            }
                          }
                        ],
                        meta: {
                          "layout": false
                        }
                      }
                    ],
                    meta: {
                      "layout": false
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              },
              {
                path: 'netsales',
                /* internal name: 'app-reports-transactions-netsales-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-reports-transactions-netsales',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/netsales/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "locations",
                      "showExport": true,
                      "showGroups": true,
                      "showLocations": true,
                      "showConsolidated": true,
                      "showRevenueCenter": true,
                      "showCurrency": true,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": false,
                      "showPresets": true,
                      "disablePdf": true,
                      "disableExcel": false,
                      "showSelected": false,
                      "generateSingleLocationTo": "app-reports-transactions-netsales-id",
                      "generateSingleLocationRcTo": "app-reports-transactions-netsales-id-revenuecenter",
                      "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                    }
                  },
                  {
                    path: ':id',
                    /* internal name: 'app-reports-transactions-netsales-id-root' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'app-reports-transactions-netsales-id',
                        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/netsales/[id]/index.vue'),
                        /* no children */
                        meta: {
                          "layout": "pagination",
                          "width": "full",
                          "paginator": true,
                          "showExport": true,
                          "showGroups": true,
                          "showLocations": true,
                          "showConsolidated": true,
                          "showRevenueCenter": true,
                          "showCurrency": true,
                          "showDateRange": true,
                          "showSubmit": true,
                          "disableMultiselect": false,
                          "disablePdf": true,
                          "showPresets": true,
                          "disableExcel": false,
                          "showSelected": false,
                          "pageHeaderMenuTitle": "Actions",
                          "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                          "generateTo": "app-reports-transactions-netsales",
                          "paginationLabel": "dates",
                          "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                        }
                      },
                      {
                        path: 'revenuecenter',
                        /* internal name: 'app-reports-transactions-netsales-id-revenuecenter-root' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: 'app-reports-transactions-netsales-id-revenuecenter',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/netsales/[id]/revenuecenter/index.vue'),
                            /* no children */
                            meta: {
                              "layout": "pagination",
                              "width": "full",
                              "paginator": true,
                              "showExport": true,
                              "showGroups": true,
                              "showLocations": true,
                              "showConsolidated": true,
                              "showRevenueCenter": true,
                              "showCurrency": true,
                              "showDateRange": true,
                              "showSubmit": true,
                              "disableMultiselect": false,
                              "disablePdf": true,
                              "showPresets": true,
                              "disableExcel": false,
                              "showSelected": false,
                              "pageHeaderMenuTitle": "Actions",
                              "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                              "generateTo": "app-reports-transactions-netsales",
                              "paginationLabel": "revenue centers",
                              "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                            }
                          },
                          {
                            path: ':remoteId',
                            /* internal name: 'app-reports-transactions-netsales-id-revenuecenter-remoteid-root' */
                            /* no component */
                            children: [
                              {
                                path: '',
                                name: 'app-reports-transactions-netsales-id-revenuecenter-remoteid',
                                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/netsales/[id]/revenuecenter/[remoteId]/index.vue'),
                                /* no children */
                                meta: {
                                  "layout": "pagination",
                                  "width": "full",
                                  "paginator": true,
                                  "showExport": true,
                                  "showGroups": true,
                                  "showLocations": true,
                                  "showConsolidated": true,
                                  "showRevenueCenter": true,
                                  "showCurrency": true,
                                  "showDateRange": true,
                                  "showSubmit": true,
                                  "disableMultiselect": false,
                                  "disablePdf": true,
                                  "showPresets": true,
                                  "disableExcel": false,
                                  "showSelected": false,
                                  "pageHeaderMenuTitle": "Actions",
                                  "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                                  "generateTo": "app-reports-transactions-netsales",
                                  "paginationLabel": "dates",
                                  "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                                }
                              }
                            ],
                            meta: {
                              "layout": false
                            }
                          }
                        ],
                        meta: {
                          "layout": false
                        }
                      }
                    ],
                    meta: {
                      "layout": false
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              },
              {
                path: 'salesperhour',
                /* internal name: 'app-reports-transactions-salesperhour-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-reports-transactions-salesperhour',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/salesperhour/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "locations",
                      "showExport": true,
                      "showGroups": true,
                      "showLocations": true,
                      "showConsolidated": true,
                      "showRevenueCenter": true,
                      "showCurrency": true,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": false,
                      "disablePdf": true,
                      "showPresets": true,
                      "disableExcel": false,
                      "showSelected": false,
                      "generateSingleLocationTo": "app-reports-transactions-salesperhour-id",
                      "generateSingleLocationRcTo": "app-reports-transactions-salesperhour-id-revenuecenter",
                      "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                    }
                  },
                  {
                    path: ':id',
                    /* internal name: 'app-reports-transactions-salesperhour-id-root' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'app-reports-transactions-salesperhour-id',
                        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/salesperhour/[id]/index.vue'),
                        /* no children */
                        meta: {
                          "layout": "pagination",
                          "width": "full",
                          "paginator": true,
                          "paginationLabel": "locations",
                          "showExport": true,
                          "showGroups": true,
                          "showLocations": true,
                          "showConsolidated": true,
                          "showRevenueCenter": true,
                          "showCurrency": true,
                          "showDateRange": true,
                          "showSubmit": true,
                          "disableMultiselect": false,
                          "disablePdf": true,
                          "showPresets": true,
                          "disableExcel": false,
                          "showSelected": false,
                          "generateTo": "app-reports-transactions-salesperhour",
                          "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                        }
                      },
                      {
                        path: 'revenuecenter',
                        /* internal name: 'app-reports-transactions-salesperhour-id-revenuecenter-root' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: 'app-reports-transactions-salesperhour-id-revenuecenter',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/salesperhour/[id]/revenuecenter/index.vue'),
                            /* no children */
                            meta: {
                              "layout": "pagination",
                              "width": "full",
                              "paginator": true,
                              "paginationLabel": "revenue centers",
                              "showExport": true,
                              "showGroups": true,
                              "showLocations": true,
                              "showConsolidated": true,
                              "showRevenueCenter": true,
                              "showCurrency": true,
                              "showDateRange": true,
                              "showSubmit": true,
                              "disableMultiselect": false,
                              "disablePdf": true,
                              "showPresets": true,
                              "disableExcel": false,
                              "showSelected": false,
                              "generateTo": "app-reports-transactions-salesperhour",
                              "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                            }
                          },
                          {
                            path: ':remoteId',
                            /* internal name: 'app-reports-transactions-salesperhour-id-revenuecenter-remoteid-root' */
                            /* no component */
                            children: [
                              {
                                path: '',
                                name: 'app-reports-transactions-salesperhour-id-revenuecenter-remoteid',
                                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/salesperhour/[id]/revenuecenter/[remoteId]/index.vue'),
                                /* no children */
                                meta: {
                                  "layout": "pagination",
                                  "width": "full",
                                  "paginator": true,
                                  "paginationLabel": "dates",
                                  "showExport": true,
                                  "showGroups": true,
                                  "showLocations": true,
                                  "showConsolidated": true,
                                  "showRevenueCenter": true,
                                  "showCurrency": true,
                                  "showDateRange": true,
                                  "showSubmit": true,
                                  "disableMultiselect": false,
                                  "disablePdf": true,
                                  "showPresets": true,
                                  "disableExcel": false,
                                  "showSelected": false,
                                  "generateTo": "app-reports-transactions-salesperhour",
                                  "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                                }
                              }
                            ],
                            meta: {
                              "layout": false
                            }
                          }
                        ],
                        meta: {
                          "layout": false
                        }
                      }
                    ],
                    meta: {
                      "layout": false
                    }
                  },
                  {
                    path: 'revenuecenters',
                    /* internal name: 'app-reports-transactions-salesperhour-revenuecenters' */
                    /* no component */
                    children: [
                      {
                        path: ':remoteId',
                        name: 'app-reports-transactions-salesperhour-revenuecenters-remoteid',
                        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/salesperhour/revenuecenters/[remoteId].vue'),
                        /* no children */
                        meta: {
                          "layout": "pagination",
                          "width": "full",
                          "paginator": true,
                          "paginationLabel": "locations",
                          "showExport": true,
                          "showGroups": true,
                          "showLocations": true,
                          "showConsolidated": true,
                          "showRevenueCenter": true,
                          "showCurrency": true,
                          "showDateRange": true,
                          "showSubmit": true,
                          "disableMultiselect": false,
                          "disablePdf": true,
                          "showPresets": true,
                          "disableExcel": false,
                          "showSelected": false,
                          "generateTo": "app-reports-transactions-salesperhour",
                          "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the check."
                        }
                      }
                    ],
                    meta: {
                      "layout": false
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              },
              {
                path: 'salesrevenue',
                name: 'app-reports-transactions-salesrevenue',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/salesrevenue.vue'),
                /* no children */
                meta: {
                  "layout": "pagination",
                  "width": "medium",
                  "paginator": true,
                  "disableExport": true,
                  "paginationLabel": "locations",
                  "pageHeaderMenuTitle": "Actions",
                  "labelExport": "Export users list",
                  "pageHeaderMenuDescription": "Search for specific info, export your list, etc.+",
                  "showExport": true,
                  "showGroups": true,
                  "showLocations": true,
                  "showConsolidated": false,
                  "showRevenueCenter": false,
                  "showCurrency": true,
                  "showDateRange": true,
                  "showSubmit": true,
                  "showPresets": true,
                  "disableMultiselect": false,
                  "disablePdf": false,
                  "disableExcel": false,
                  "showSelected": false
                }
              },
              {
                path: 'voids',
                /* internal name: 'app-reports-transactions-voids-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-reports-transactions-voids',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/voids/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "locations",
                      "showExport": true,
                      "showGroups": true,
                      "showLocations": true,
                      "showConsolidated": true,
                      "showRevenueCenter": true,
                      "showCurrency": true,
                      "showDateRange": true,
                      "showSubmit": true,
                      "disableMultiselect": false,
                      "disablePdf": true,
                      "showPresets": true,
                      "disableExcel": false,
                      "showSelected": false,
                      "generateSingleLocationTo": "app-reports-transactions-voids-id",
                      "generateSingleLocationRcTo": "app-reports-transactions-voids-id-revenuecenter",
                      "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                    }
                  },
                  {
                    path: ':id',
                    /* internal name: 'app-reports-transactions-voids-id-root' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'app-reports-transactions-voids-id',
                        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/voids/[id]/index.vue'),
                        /* no children */
                        meta: {
                          "layout": "pagination",
                          "width": "full",
                          "paginator": true,
                          "showExport": true,
                          "showGroups": true,
                          "showLocations": true,
                          "showConsolidated": true,
                          "showRevenueCenter": true,
                          "showCurrency": true,
                          "showDateRange": true,
                          "showSubmit": true,
                          "disableMultiselect": false,
                          "disablePdf": true,
                          "showPresets": true,
                          "disableExcel": false,
                          "showSelected": false,
                          "generateTo": "app-reports-transactions-voids",
                          "paginationLabel": "dates",
                          "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                        }
                      },
                      {
                        path: ':date',
                        /* internal name: 'app-reports-transactions-voids-id-date-root' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: 'app-reports-transactions-voids-id-date',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/voids/[id]/[date]/index.vue'),
                            /* no children */
                            meta: {
                              "layout": "pagination",
                              "width": "full",
                              "paginator": true,
                              "paginationLabel": "voids",
                              "showExport": true,
                              "showGroups": true,
                              "showLocations": true,
                              "showRevenueCenter": true,
                              "showConsolidated": true,
                              "showCurrency": true,
                              "showDateRange": true,
                              "showSubmit": true,
                              "disableMultiselect": false,
                              "disablePdf": true,
                              "showPresets": true,
                              "disableExcel": false,
                              "showSelected": false,
                              "pageHeaderMenuTitle": "Actions",
                              "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                              "generateTo": "app-reports-transactions-voids",
                              "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                            }
                          }
                        ],
                        meta: {
                          "layout": false
                        }
                      },
                      {
                        path: 'revenuecenter',
                        /* internal name: 'app-reports-transactions-voids-id-revenuecenter-root' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: 'app-reports-transactions-voids-id-revenuecenter',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/voids/[id]/revenuecenter/index.vue'),
                            /* no children */
                            meta: {
                              "layout": "pagination",
                              "width": "full",
                              "paginator": true,
                              "showExport": true,
                              "showGroups": true,
                              "showLocations": true,
                              "showConsolidated": true,
                              "showRevenueCenter": true,
                              "showCurrency": true,
                              "showDateRange": true,
                              "showSubmit": true,
                              "disableMultiselect": false,
                              "disablePdf": true,
                              "showPresets": true,
                              "disableExcel": false,
                              "showSelected": false,
                              "generateTo": "app-reports-transactions-voids",
                              "paginationLabel": "revenue centers",
                              "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                            }
                          },
                          {
                            path: ':remoteId',
                            /* internal name: 'app-reports-transactions-voids-id-revenuecenter-remoteid-root' */
                            /* no component */
                            children: [
                              {
                                path: '',
                                name: 'app-reports-transactions-voids-id-revenuecenter-remoteid',
                                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/voids/[id]/revenuecenter/[remoteId]/index.vue'),
                                /* no children */
                                meta: {
                                  "layout": "pagination",
                                  "width": "full",
                                  "paginator": true,
                                  "showExport": true,
                                  "showGroups": true,
                                  "showLocations": true,
                                  "showConsolidated": true,
                                  "showRevenueCenter": true,
                                  "showCurrency": true,
                                  "showDateRange": true,
                                  "showSubmit": true,
                                  "disableMultiselect": false,
                                  "disablePdf": true,
                                  "showPresets": true,
                                  "disableExcel": false,
                                  "showSelected": false,
                                  "generateTo": "app-reports-transactions-voids",
                                  "paginationLabel": "dates",
                                  "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                                }
                              },
                              {
                                path: ':date',
                                name: 'app-reports-transactions-voids-id-revenuecenter-remoteid-date',
                                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/reports/transactions/voids/[id]/revenuecenter/[remoteId]/[date].vue'),
                                /* no children */
                                meta: {
                                  "layout": "pagination",
                                  "width": "full",
                                  "paginator": true,
                                  "showExport": true,
                                  "showGroups": true,
                                  "showLocations": true,
                                  "showConsolidated": true,
                                  "showRevenueCenter": true,
                                  "showCurrency": true,
                                  "showDateRange": true,
                                  "showSubmit": true,
                                  "disableMultiselect": false,
                                  "disablePdf": true,
                                  "showPresets": true,
                                  "disableExcel": false,
                                  "showSelected": false,
                                  "generateTo": "app-reports-transactions-voids",
                                  "paginationLabel": "voids",
                                  "revenueCenterTooltip": "The gross sales by revenue center will be grouped based on the items listed on the check."
                                }
                              }
                            ],
                            meta: {
                              "layout": false
                            }
                          }
                        ],
                        meta: {
                          "layout": false
                        }
                      }
                    ],
                    meta: {
                      "layout": false
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              }
            ],
            meta: {
              "layout": false
            }
          }
        ],
        meta: {
          "layout": false
        }
      },
      {
        path: 'setup',
        /* internal name: 'app-setup-root' */
        /* no component */
        children: [
          {
            path: '',
            name: 'app-setup',
            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/index.vue'),
            /* no children */
            meta: {
              "layout": "home"
            }
          },
          {
            path: 'admin',
            /* internal name: 'app-setup-admin-root' */
            /* no component */
            children: [
              {
                path: '',
                name: 'app-setup-admin',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/index.vue'),
                /* no children */
                meta: {
                  "layout": "home",
                  "showToolbar": false
                }
              },
              {
                path: 'analytics',
                name: 'app-setup-admin-analytics',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/analytics.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              },
              {
                path: 'coupons',
                /* internal name: 'app-setup-admin-coupons-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-setup-admin-coupons',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/coupons/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "showSearch": true,
                      "showToolbar": true,
                      "paginator": true,
                      "paginationLabel": "coupons",
                      "showExport": true,
                      "showGroups": true,
                      "showSubmit": true,
                      "disableMultiselect": true,
                      "showCreate": true,
                      "split": true,
                      "labelCreate": "Create new coupon",
                      "labelExport": "Export coupons list",
                      "pageHeaderMenuTitle": "Actions",
                      "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                      "forceGroups": true,
                      "splitMenu": [
                        {
                          "label": "Create new coupon",
                          "icon": "pi pi-plus",
                          "to": {
                            "query": {
                              "mode": "create"
                            }
                          }
                        },
                        {
                          "label": "Import coupons",
                          "icon": "pi pi-upload",
                          "disabled": true,
                          "to": {
                            "query": {
                              "mode": "import"
                            }
                          }
                        },
                        {
                          "label": "Delete coupons",
                          "icon": "pi pi-trash",
                          "to": {
                            "query": {
                              "mode": "delete"
                            }
                          }
                        }
                      ]
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              },
              {
                path: 'emloyees',
                name: 'app-setup-admin-emloyees',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/emloyees.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              },
              {
                path: 'groups',
                /* internal name: 'app-setup-admin-groups-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-setup-admin-groups',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/groups/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "showSearch": true,
                      "showToolbar": true,
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "groups",
                      "showExport": false,
                      "showSubmit": false,
                      "showCreate": true,
                      "labelCreate": "Create new group",
                      "pageHeaderMenuTitle": "Actions",
                      "pageHeaderMenuDescription": "Search for specific info, export your list, etc."
                    }
                  },
                  {
                    path: 'edit',
                    /* internal name: 'app-setup-admin-groups-edit' */
                    /* no component */
                    children: [
                      {
                        path: ':id',
                        name: 'app-setup-admin-groups-edit-id-root',
                        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/groups/edit/[id].vue'),
                        children: [
                          {
                            path: '',
                            name: 'app-setup-admin-groups-edit-id',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/groups/edit/[id]/index.vue'),
                            /* no children */
                            meta: {
                              "width": "small",
                              "disableHeading": true,
                              "showToolbar": false,
                              "layout": false
                            }
                          },
                          {
                            path: 'locations',
                            name: 'app-setup-admin-groups-edit-id-locations',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/groups/edit/[id]/locations.vue'),
                            /* no children */
                            meta: {
                              "width": "small",
                              "disableHeading": false,
                              "showSearch": false,
                              "showToolbar": true,
                              "paginator": false,
                              "showExport": false,
                              "showSubmit": true,
                              "showCreate": true,
                              "labelGenerate": "Refresh locations list",
                              "labelCreate": "Add location(s)",
                              "pageHeaderMenuTitle": "Actions",
                              "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                              "layout": false
                            }
                          },
                          {
                            path: 'users',
                            name: 'app-setup-admin-groups-edit-id-users',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/groups/edit/[id]/users.vue'),
                            /* no children */
                            meta: {
                              "width": "small",
                              "disableHeading": false,
                              "showSearch": true,
                              "showToolbar": true,
                              "paginator": false,
                              "showExport": false,
                              "showSubmit": false,
                              "pageHeaderMenuTitle": "Actions",
                              "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                              "layout": false
                            }
                          }
                        ],
                        meta: {
                          "layout": "home-no-scroll",
                          "width": "small",
                          "disableHeading": true
                        }
                      }
                    ],
                    meta: {
                      "layout": false
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              },
              {
                path: 'locations',
                /* internal name: 'app-setup-admin-locations-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-setup-admin-locations',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/locations/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "showSearch": true,
                      "showToolbar": true,
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "locations",
                      "showExport": true,
                      "labelExport": "Export locations list",
                      "disablePdf": true,
                      "disableExcel": false,
                      "pageHeaderMenuTitle": "Actions",
                      "pageHeaderMenuDescription": "Search for specific info, export your list, etc."
                    }
                  },
                  {
                    path: 'edit',
                    /* internal name: 'app-setup-admin-locations-edit' */
                    /* no component */
                    children: [
                      {
                        path: ':id',
                        name: 'app-setup-admin-locations-edit-id-root',
                        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/locations/edit/[id].vue'),
                        children: [
                          {
                            path: '',
                            name: 'app-setup-admin-locations-edit-id',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/locations/edit/[id]/index.vue'),
                            /* no children */
                            meta: {
                              "width": "small",
                              "disableHeading": true,
                              "layout": false
                            }
                          },
                          {
                            path: 'groups',
                            name: 'app-setup-admin-locations-edit-id-groups',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/locations/edit/[id]/groups.vue'),
                            /* no children */
                            meta: {
                              "width": "small",
                              "disableHeading": false,
                              "showSearch": false,
                              "showToolbar": true,
                              "paginator": false,
                              "showExport": false,
                              "showSubmit": true,
                              "showCreate": true,
                              "labelGenerate": "Refresh groups list",
                              "labelCreate": "Add to group",
                              "pageHeaderMenuTitle": "Actions",
                              "pageHeaderMenuDescription": "Search for specific info, export your list, etc.",
                              "layout": false
                            }
                          },
                          {
                            path: 'users',
                            name: 'app-setup-admin-locations-edit-id-users',
                            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/locations/edit/[id]/users.vue'),
                            /* no children */
                            meta: {
                              "width": "small",
                              "disableHeading": true,
                              "layout": false
                            }
                          }
                        ],
                        meta: {
                          "layout": "home-no-scroll",
                          "width": "small",
                          "disableHeading": true
                        }
                      }
                    ],
                    meta: {
                      "layout": false
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              },
              {
                path: 'resellers',
                name: 'app-setup-admin-resellers',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/resellers.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              },
              {
                path: 'users',
                /* internal name: 'app-setup-admin-users-root' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-setup-admin-users',
                    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/users/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "pagination",
                      "width": "full",
                      "paginator": true,
                      "paginationLabel": "users",
                      "showSearch": true,
                      "showToolbar": true,
                      "showExport": false,
                      "showCreate": true,
                      "labelCreate": "Create new user",
                      "showSubmit": false,
                      "labelGenerate": "Create new user",
                      "labelExport": "Export users list",
                      "pageHeaderMenuTitle": "Actions",
                      "pageHeaderMenuDescription": "Search for specific info, export your list, etc."
                    }
                  },
                  {
                    path: 'edit',
                    /* internal name: 'app-setup-admin-users-edit' */
                    /* no component */
                    children: [
                      {
                        path: ':id',
                        name: 'app-setup-admin-users-edit-id',
                        component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/admin/users/edit/[id].vue'),
                        /* no children */
                        meta: {
                          "layout": "home-no-scroll",
                          "width": "small",
                          "disableHeading": true
                        }
                      }
                    ],
                    meta: {
                      "layout": false
                    }
                  }
                ],
                meta: {
                  "layout": false
                }
              }
            ],
            meta: {
              "layout": false
            }
          },
          {
            path: 'business',
            name: 'app-setup-business',
            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/business.vue'),
            /* no children */
            meta: {
              "layout": "home"
            }
          },
          {
            path: 'discounts',
            name: 'app-setup-discounts',
            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/discounts.vue'),
            /* no children */
            meta: {
              "layout": "home"
            }
          },
          {
            path: 'language',
            name: 'app-setup-language',
            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/language.vue'),
            /* no children */
            meta: {
              "layout": "home"
            }
          },
          {
            path: 'menu',
            /* internal name: 'app-setup-menu-root' */
            /* no component */
            children: [
              {
                path: '',
                name: 'app-setup-menu',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/menu/index.vue'),
                /* no children */
                meta: {
                  "layout": "home",
                  "showToolbar": false
                }
              },
              {
                path: 'categories',
                name: 'app-setup-menu-categories',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/menu/categories.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              },
              {
                path: 'combos',
                name: 'app-setup-menu-combos',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/menu/combos.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              },
              {
                path: 'concepts',
                name: 'app-setup-menu-concepts',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/menu/concepts.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              },
              {
                path: 'counters',
                name: 'app-setup-menu-counters',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/menu/counters.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              },
              {
                path: 'coursing',
                name: 'app-setup-menu-coursing',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/menu/coursing.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              },
              {
                path: 'itemprofile',
                name: 'app-setup-menu-itemprofile',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/menu/itemprofile.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              },
              {
                path: 'items',
                name: 'app-setup-menu-items',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/menu/items.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              },
              {
                path: 'mixandmatch',
                name: 'app-setup-menu-mixandmatch',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/menu/mixandmatch.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              },
              {
                path: 'popups',
                name: 'app-setup-menu-popups',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/menu/popups.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              },
              {
                path: 'subcategories',
                name: 'app-setup-menu-subcategories',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/menu/subcategories.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              }
            ],
            meta: {
              "layout": false
            }
          },
          {
            path: 'settings',
            name: 'app-setup-settings',
            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/settings.vue'),
            /* no children */
            meta: {
              "layout": "home"
            }
          },
          {
            path: 'system',
            /* internal name: 'app-setup-system-root' */
            /* no component */
            children: [
              {
                path: '',
                name: 'app-setup-system',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/system/index.vue'),
                /* no children */
                meta: {
                  "layout": "home",
                  "showToolbar": false
                }
              },
              {
                path: 'sitestatus',
                name: 'app-setup-system-sitestatus',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/system/sitestatus.vue'),
                /* no children */
                meta: {
                  "layout": "pagination",
                  "width": "large",
                  "labelGenerate": "Refresh",
                  "showToolbar": true,
                  "showSearch": true,
                  "paginator": true,
                  "paginationLabel": "locations",
                  "showGroups": true,
                  "showExport": true,
                  "showSubmit": true,
                  "pageHeaderMenuTitle": "Site status options",
                  "pageHeaderMenuDescription": "Choose the settings."
                }
              }
            ],
            meta: {
              "layout": false
            }
          },
          {
            path: 'taxes',
            /* internal name: 'app-setup-taxes-root' */
            /* no component */
            children: [
              {
                path: '',
                name: 'app-setup-taxes',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/taxes/index.vue'),
                /* no children */
                meta: {
                  "layout": "home",
                  "showToolbar": false
                }
              },
              {
                path: 'categories',
                name: 'app-setup-taxes-categories',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/taxes/categories.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              },
              {
                path: 'taxes',
                name: 'app-setup-taxes-taxes',
                component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/setup/taxes/taxes.vue'),
                /* no children */
                meta: {
                  "layout": "home"
                }
              }
            ],
            meta: {
              "layout": false
            }
          }
        ],
        meta: {
          "layout": false
        }
      },
      {
        path: 'utility',
        /* internal name: 'app-utility-root' */
        /* no component */
        children: [
          {
            path: '',
            name: 'app-utility',
            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/utility/index.vue'),
            /* no children */
            meta: {
              "layout": "home"
            }
          },
          {
            path: 'srm',
            name: 'app-utility-srm',
            component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/pages/utility/srm.vue'),
            /* no children */
            meta: {
              "layout": "home-no-scroll",
              "width": "small",
              "disableHeading": true
            }
          }
        ],
        meta: {
          "layout": false
        }
      }
    ],
    meta: {
      "layout": false
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/public/contact.vue'),
    /* no children */
    meta: {
      "layout": "default"
    }
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/public/forgotpassword.vue'),
    /* no children */
    meta: {
      "layout": "default"
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/public/login.vue'),
    /* no children */
    meta: {
      "layout": "default"
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/views/public/reset-password.vue'),
    /* no children */
    meta: {
      "layout": "default"
    }
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

